<template>
  <footer class="mt-5">
    <div class="container row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 pt-5 pb-lg-4 mx-auto">
      <div class="col mb-3 mb-lg-0">
        <router-link to="/" class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
          <img :src="logoPath" width="100" />
        </router-link>
        <p class="text-body-secondary">© 2024 YMCA. All right reserved.</p>
      </div>

      <div class="col mb-3 mb-lg-0">

      </div>

      <div class="col mb-3 mb-lg-0">
        <h5>メニュー</h5>
        <ul class="nav flex-column ms-2">
          <li class="nav-item mb-2"><router-link to="/static/faq" class="nav-link p-0 text-body-secondary">よくあるご質問</router-link></li>
          <li class="nav-item mb-2"><router-link to="/inquiry/index" class="nav-link p-0 text-body-secondary">お問い合わせ</router-link></li>
          <!-- <li class="nav-item mb-2"><router-link to="/request/index" class="nav-link p-0 text-body-secondary">ご意見・ご要望</router-link></li> -->
        </ul>
      </div>

      <div class="col mb-3 mb-lg-0">
        <h5>YMCAポータルについて</h5>
        <ul class="nav flex-column ms-2">
          <li class="nav-item mb-2"><router-link to="/static/browser" class="nav-link p-0 text-body-secondary">推奨環境</router-link></li>
          <li class="nav-item mb-2"><router-link to="/static/rule" class="nav-link p-0 text-body-secondary">利用規約</router-link></li>
          <li class="nav-item mb-2"><router-link to="/static/privacy" class="nav-link p-0 text-body-secondary">プライバシーポリシー</router-link></li>
          <li class="nav-item mb-2"><router-link to="/static/confirmation" class="nav-link p-0 text-body-secondary">シーズンプログラム予約前確認事項</router-link></li>
          <li class="nav-item mb-2"><router-link to="/static/cancel" class="nav-link p-0 text-body-secondary">シーズンプログラムキャンセル規定</router-link></li>
          <li class="nav-item mb-2"><router-link to="/static/annual-confirmation" class="nav-link p-0 text-body-secondary">通年プログラム予約前確認事項</router-link></li>
          <li class="nav-item mb-2"><router-link to="/static/annual-cancel" class="nav-link p-0 text-body-secondary">通年プログラムキャンセル規定</router-link></li>
          <!-- <li class="nav-item mb-2"><a href="/static/trade.php" class="nav-link p-0 text-body-secondary">特定商取引法について</a></li> -->
          <!-- <li class="nav-item mb-2"><router-link to="/static/trade" class="nav-link p-0 text-body-secondary">特定商取引法について</router-link></li> -->
        </ul>
      </div>
    </div>
  </footer>
</template>


<script lang="ts"> 
import Logo from '@/images/logo.svg'
import $ from 'jquery'

export default {
  name: 'FooterBar',
  components: {
    // Logo
  },
  data() {
    return {
      logoPath: Logo,
    }
  },
  mounted() {
    const vm = this
      $('.navbar-toggler').on('click', function () {
        if ($('body').css('overflow') === 'hidden') {
          $('body').css({ height: '', overflow: '' })
      } else {
        $('body').css({ height: '100%', overflow: 'hidden' })
      }
    })
    // const collapseNavItems = document.querySelectorAll('.collapse .nav li')
    // collapseNavItems.forEach(item => {
    //   item.addEventListener('click', () => {
    //     const collapse = item.closest('.collapse')
    //     if (collapse) {
    //       collapse.classList.remove('show')
    //     }
    //   })
    // })
  },
  methods: {
    // click() {
    //   $(function () {
    //     console.log('jqueryテストbotannnnnn')
    //   })
    // }
  }
}
</script>