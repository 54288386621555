import { toDateDotJst } from '@/js/util'
import { API } from '../api'
import { TMember } from './member'
import { useLoadingStore } from '@/stores/loading'
import { useMemberStore } from '@/stores/member'
import { TCategoryType, TEventMember, TShopType, TTargetType } from './search'

const resource = '/api/event/'



export class TEvent {
  id: number
  path: string
  title: string
  category: number
  kind: number
  content: string
  capacity: number
  displayCapacity: string
  cancelType: number
  daysUntilCancel: number
  cancellationDeadline: Date
  target: number
  priceDetail: number
  includedInPrice: string
  excludedInPrice: string
  paymentMethod: number
  destination: string
  gatheringDisbanding: string
  manager: string
  shop: number
  link: string
  stay: number
  anchorText: string
  isDisplayImage: boolean
  other: string
  displayPeriodStart: Date
  displayPeriodEnd: Date
  recruitmentPeriodStart: Date
  recruitmentPeriodEnd: Date
  applicantParent: boolean
  applicantChild: boolean
  item: string
  messageBefore: string
  schedule: string
  scheduleName: string
  messageAfter: string
  categoryType: TCategoryType
  kindType: TKindType
  targetType: TTargetType[]
  schedules: TEventSchedule[]
  participants: TEventParticipant[]
  department: TShop | null
  eventMember: TEventMember[] | null
  // schedules: TEventSchedule[]
  // payments: TPayment[]
  images: TImage[]
  base: TBase | null
  sideNote: string
  timeTables: TTimeTable[]
  timeTableRows: number
  timeTableCols: number
  isDisplay: boolean
  isApply: boolean
  serverNow: string
  classCode: string
  fiscalYear: number

  constructor(data: any) {
    this.id = Number(data.id) || 0
    this.path = data.path
    this.title = data.title
    this.category = data.category
    this.kind = data.kind
    this.content = data.content
    this.capacity = Number(data.capacity)
    this.displayCapacity = data.display_capacity
    this.cancelType = data.cancel_type
    this.daysUntilCancel = data.days_until_cancel
    this.cancellationDeadline = data.cancellation_deadline
    this.target = Number(data.target)
    this.priceDetail = data.price_detail
    this.includedInPrice = data.included_in_price
    this.excludedInPrice = data.excluded_in_price
    this.paymentMethod = Number(data.payment_method)
    this.destination = data.destination
    this.gatheringDisbanding = data.gathering_disbanding
    this.manager = data.manager
    this.shop = data.shop
    this.link = data.link
    this.stay = data.stay
    this.anchorText = data.anchor_text
    this.isDisplayImage = data.is_display_image
    this.other = data.other
    this.displayPeriodStart = data.display_period_start
    this.displayPeriodEnd = data.display_period_end
    this.recruitmentPeriodStart = data.recruitment_period_start
    this.recruitmentPeriodEnd = data.recruitment_period_end
    this.applicantParent = data.applicant_parent
    this.applicantChild = data.applicant_child
    this.item = data.item
    this.messageBefore = data.message_before
    this.schedule = data.schedule
    this.scheduleName = data.schedule_name
    this.messageAfter = data.message_after
    this.categoryType = data.category_type
    this.kindType = data.kind_type
    this.targetType = data.target_types
    this.schedules =  ('schedules' in data) ? (Object.keys(data.schedules).map(key => data.schedules[key]) as any[]).map(d => new TEventSchedule(d)) : []
    this.participants = ('participants' in data) ? (Object.keys(data.participants).map(key => data.participants[key]) as any[]).map(d => new TEventParticipant(d)) : []
    this.department = ('department' in data)?data.department ? new TShop(data.department) : null:null
    this.eventMember = ('event_member' in data)?(Object.keys(data.event_member).map(key => data.event_member[key]) as any[]).map(d => new TEventMember(d)) : []
    // this.payments = ('payments' in data) ? (Object.keys(data.payments).map(key => data.payments[key]) as any[]).map(d => new TPayment(d)) : []
    this.images = ('images' in data) ? (Object.keys(data.images).map(key => data.images[key]) as any[]).map(d => new TImage(d)) : []
    this.base = ('base_data' in data && data.base_data && Object.keys(data.base_data).length > 0) ? new TBase(data.base_data): null
    this.sideNote = data.side_note
    this.timeTables = ('timetables' in data) ? (Object.keys(data.timetables).map(key => data.timetables[key]) as any[]).map(d => new TTimeTable(d)) : [TTimeTable.emptyTimeTable(data.id, 1)]
    this.timeTableRows = data.time_table_rows || 1
    this.timeTableCols = data.time_table_cols || 3
    this.isDisplay = data.is_display
    this.isApply = data.is_apply
    this.serverNow = data.server_now
    this.classCode = data.class_code
    this.fiscalYear = data.fiscal_year
  }

  static fetch({ path, id }: { path: string, id? : number }) {
    const params = { path: path, id: id }
    return API.get(resource.concat('detail'), { params }).then((r) => {
      return {
        event: new TEvent(r.data.event),
        eventTargets: (r.data.event_targets as any[]).map(data => new TEventTarget(data)),
        eventMemberships: (r.data.event_memberships as any[]).map(data => new TEventMembership(data)),
        eventSchedules: (r.data.schedules as any[]).map(data => new TEventSchedule(data)),
        memberIds: r.data.member_ids,
      }
    })
  }

  static fetchEventMember({ id, memberId }: { id: number, memberId: number }) {
    const params = { id: id, member_id: memberId }
    return API.get(resource.concat('detail-members'), { params }).then((r) => {
      const uniquePaymentMethods: TPaymentMethod[] = [];
      const seenPaymentIds = new Set<number>();
      r.data.payment_methods.forEach(data => {
          if (!seenPaymentIds.has(data.type_id)) {
              uniquePaymentMethods.push(data);
              seenPaymentIds.add(data.type_id);
          }
      });
      return {
        event: new TEvent(r.data.event),
        parent: new TMember(r.data.parent),
        children: (r.data.children as any[]).map(data => new TMember(data)),
        schedules: (r.data.schedules as any[]).map(data => new TEventSchedule(data)),
        eventTargets: (r.data.event_targets as any[]).map(data => new TEventTarget(data)),
        paymentMethods: (uniquePaymentMethods as any[]).map(data => new TPaymentMethod(data)),
        surveys: (r.data.surveys as any[]).map(data => new TSurvey(data)),
      }
    })
  }
  static fetchAttendEventMember({ id, memberId, code }: { id: number, memberId: number, code: string }) {
    const params = { id: id, member_id: memberId, code: code }
    return API.get(resource.concat('detail-event-members'), { params }).then((r) => {
      return {
        event: new TEvent(r.data.event),
        schedules: new TEventSchedule(r.data.schedule),
        eventTargets: (r.data.event_targets as any[]).map(data => new TEventTarget(data)),
        eventMember: r.data.eventMember,
        answers: r.data.answers,
        now: r.data.now,
        isAfter: r.data.isAfter, 
      }
    })
  }

  static async cancel({
    eventArg,
    eventMemberArg,
    schedulesArg,
    answersArg,
  }: {
    eventArg: TEvent | undefined,
    eventMemberArg: any[] | undefined,
    schedulesArg: TEventSchedule | undefined,
    answersArg: any[] | undefined,
  }): Promise<string> {
    const params = {
      event: eventArg,
      eventMember: eventMemberArg,
      schedules: schedulesArg,
      answers: answersArg,
    }
    const response = await API.post(resource.concat('cancel'), params)
    if (response.data.status === 'failed') {
      alert(response.data.message);
      return 'failed';
    }
    return 'success';
  }

  static async check({schedulesArg, rentalsArg}: {schedulesArg: TEventSchedule[] | undefined, rentalsArg: TRental[] | undefined }): Promise<string> {
    const params = {
      schedules: schedulesArg,
      rentals: rentalsArg,
    }
    const response = await API.post(resource.concat('check'), params)
    useLoadingStore().stop()
    if (response.data.status === 'failed') {
      alert(response.data.message);
      return 'failed';
    } else {
      return 'success';
    }
  }

  static async save({
    eventArg,
    parentArg,
    childrenArg,
    schedulesArg,
    paymentMethodsArg,
    surveysArg,
    rentalsArg,
    paymentIdArg
  }: {
    eventArg: TEvent | undefined,
    parentArg: TMember | undefined,
    childrenArg: TMember[] | undefined,
    schedulesArg: TEventSchedule[] | undefined,
    paymentMethodsArg: TPaymentMethod[] | undefined,
    surveysArg: TSurvey[] | undefined,
    rentalsArg: TRental[] | undefined,
    paymentIdArg: number
  }): Promise<string> {
    let paymentData: { total: number; code: number; id:number }[] = [];
    schedulesArg?.forEach(schedule => {
      rentalsArg?.forEach(rental => {
        let total = 0;
        total += eventArg ? eventArg.priceDetail : 0
        total += rental.getTotalChoicesValue()
        // }
        paymentData.push({total:total,code:schedule.eventCode,id:rental.member.id})
      })
    
  })

    const params = {
      event: eventArg,
      parent: parentArg,
      children: childrenArg,
      schedules: schedulesArg,
      payment_methods: paymentMethodsArg,
      surveys: surveysArg,
      rentals: rentalsArg,
      payment_id: paymentIdArg,
      paymentData: paymentData
    }
    const response = await API.post(resource.concat('store'), params)
    useLoadingStore().stop()
    if (response.data.status === 'failed') {
      alert(response.data.message);
      return 'failed';
    } else {
      alert(response.data.message);
      return 'success';
    }
    // return new TEvent(r.data.event)
  }

  static async fetchFiscalYearPrograms({ fiscalYear, category }: { fiscalYear: number, category : number | undefined | null }) {
    let uri = resource.concat(`all-programs/${fiscalYear}`)
    if (category !== null && category !== undefined) uri = uri.concat(`/${category}`)
    return await API.get(uri).then((r) => {
      return {
        events: (r.data.events as any[]).map(data => new TEvent(data)),
      }
    })
  }

  static async fetchCategoryTypes() {
    return await API.get('/api/master/all-category').then((r) => {
      console.log(r)
      return {
        categoryTypes: (r.data.allCategory as any[]).map(data => new TCategoryType(data))
      }
    })
  }

}

export class TEventTarget {
  id: number
  name: string
  pivot: {}

  constructor(data: any) {
    this.id = data.id,
      this.name = data.name,
      this.pivot = data.pivot
  }
}

export class TEventMembership {
  id: number
  name: string
  pivot: {}

  constructor(data: any) {
    this.id = data.id,
      this.name = data.name,
      this.pivot = data.pivot
  }
}

export class TEventSchedule {
  id: number
  eventId: number
  eventCode: number
  startAt: Date
  endAt: Date
  isChecked: boolean
  event: TEvent | null
  eventMembers: TEventMember[] | null
  paymentMethods: TPaymentMethodTable[] | null
  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.eventCode = data.event_code
    this.startAt = data.start_at
    this.endAt = data.end_at
    this.isChecked = false
    this.event = ('event' in data) && data.event ? new TEvent(data.event) : null
    this.eventMembers = ('event_member' in data) && data.event_member ? (data.event_member as any[]).map(data => new TEventMember(data)): null
    this.paymentMethods = ('payment_method' in data) && data.payment_method ? (data.payment_method as any[]).map(data => new TPaymentMethodTable(data)): null
  }
}

// payment_typesぽい
export class TPaymentMethod {
  id: number
  typeId: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.typeId = data.type_id
    this.name = data.name
  }
}

export class TPaymentMethodTable {
  id: number
  eventId: number
  eventCode: string
  paymentId: number
  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.eventCode = data.event_code
    this.paymentId = data.payment_id
  }
}

export class TChoice {
  id: number
  surveyId: number
  label: string
  value: number
  constructor(data: any) {
    this.id = data.id
    this.surveyId = data.survey_id
    this.label = data.label
    this.value = data.value
  }
}

export class TAnswer {
  id: number
  surveyId: number
  memberId: number
  choiceId: number | null
  textAnswer: string | null
  constructor(data: any) {
    this.id = data.id
    this.surveyId = data.survey_id
    this.memberId = data.member_id
    this.choiceId = data.choice_id
    this.textAnswer = data.text_answer
  }

  static fromData(id: number, surveyId: number, memberId: number, choiceId: number | null, textAnswer: string | null): TAnswer {
    return new TAnswer({ id, survey_id: surveyId, member_id: memberId, choice_id: choiceId, text_answer: textAnswer })
  }
}

export class TSurvey {
  id: number
  type: number
  content: string
  choices: TChoice[]
  constructor(data: any) {
    this.id = data.id
    this.type = data.type
    this.content = data.content
    this.choices = ('choices' in data) ? (Object.keys(data.choices).map(key => data.choices[key]) as any[]).map(d => new TChoice(d)) : []
  }
}

export class TRental {
  id: number
  memberId: number
  member: TMember
  choices: TChoice[]
  answers: TAnswer[]
  constructor(data: any) {
    this.id = data.id
    this.memberId = data.member_id
    this.member = data.member
    this.choices = data.choices
    // this.choices = [...data.choices]
    this.answers = data.answers
  }
  static fromData(id: number, memberId: number, member: TMember, choices: TChoice[] | null, answers: TAnswer[] | null): TRental {
    return new TRental({ id: id, member_id: memberId, member: member, choices: choices, answers: answers })
  }
  getTotalChoicesValue(): number {
    let total = 0
    for (const choice of this.choices) {
      total += choice.value;
    }
    return total
  }
}

export class TEventParticipant {
  id: number
  eventId: number
  eventCode: number
  capacityLimit: number
  appliedCount: number
  isApplicable: boolean
  waitlistLimit: number | null
  waitlistCount: number
  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.eventCode = data.event_code
    this.capacityLimit = data.capacity_limit
    this.appliedCount = data.applied_count
    this.isApplicable = data.is_applicable
    this.waitlistLimit = data.waitlist_limit
    this.waitlistCount = data.waitlist_count
  }
}

export class TShop {
  id: number
  name: string
  mailAddress: string
  phoneNumber: string
  reception: string | null
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.mailAddress = data.mail_address
    this.phoneNumber = data.phone_number
    this.reception = data.reception
  }
}

export class TImage {
  id: number
  eventId: number
  url: string
  name: string
  originalName: string

  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.url = data.url
    this.name = data.name
    this.originalName = data.original_name
  }
}

export class TKindType {
  id: number
  name: string

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
  }
}

export class TBase {
  id: number
  name: string
  mailAddress: string
  url: string
  phoneNumber: string
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.mailAddress = data.mail_address
    this.url = data.url
    this.phoneNumber = data.phone_number
  }
}

export class TTimeTable {
  id: number
  count: number
  eventId: number
  col1: string
  col2: string
  col3: string
  col4: string
  col5: string
  col6: string
  col7: string
  col8: string
  col9: string
  col10: string
  constructor(data: any) {
    this.id = data.id
    this.count = data.count
    this.eventId = data.event_id
    this.col1 = data.col1
    this.col2 = data.col2
    this.col3 = data.col3
    this.col4 = data.col4
    this.col5 = data.col5
    this.col6 = data.col6
    this.col7 = data.col7
    this.col8 = data.col8
    this.col9 = data.col9
    this.col10 = data.col10
  }

  static emptyTimeTable(eventId: number, count?: number) {
    return new TTimeTable({
      id: null,
      count: count || 0,
      eventId: eventId,
      col1: '',
      col2: '',
      col3: '',
      col4: '',
      col5: '',
      col6: '',
      col7: '',
      col8: '',
      col9: '',
      col10: '',
    })
  }
}
