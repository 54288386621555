<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">年度プログラム一覧</li>
    </ol>
  </nav>
  <div class="container">
    <h2>{{ props.fiscal_year }}年度 {{ category }}一覧</h2>
    <div class="program-list-inner"  v-if="events && events.length > 0">
      <div class="table-wrap table-responsive">
        <table class="normal mgT20 table mb-0" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03 tblTit">種類</th>
              <th class="tblBg03 list-nowrap">プログラム名</th>
              <th class="tblBg03 list-nowrap">対象</th>
              <th class="tblBg03 list-nowrap" v-if="category != '通年プログラム'">開催日</th>
              <th class="tblBg03 list-nowrap">開催場所</th>
              <th class="tblBg03 list-nowrap" v-if="category != '通年プログラム'">基本費用</th>
            </tr>
            <template v-for="event in events">
              <tr>
                <td class="list-nowrap">{{ event?.kindType ? event?.kindType.name : ''}}</td>
                <td class="list-nowrap">
                  <router-link :to="{ name: 'EventDetail', params: { path: event.path } }">
                    <span v-html="lineBreak(event?.title)"></span>
                  </router-link>
                </td>
                <td class="list-nowrap">{{ minTarget(event)?.name }} ～ {{ maxTarget(event)?.name }}</td>
                <td v-if="category != '通年プログラム'">
                  <template v-for="schedule in event.schedules" :key="schedule.id">
                    <div class="row m-0">
                      <div class="form-check">
                        <template v-if="schedule.startAt && schedule.endAt && schedule.startAt === schedule.endAt">
                          <label class="form-check-label list-nowrap" :for="`schedule-${schedule.id}`">{{
    toDateSlash(schedule.startAt) }} </label>
                        </template>
                        <template v-else-if="schedule.startAt && schedule.endAt">
                          <label class="form-check-label list-nowrap" :for="`schedule-${schedule.id}`">{{
    toDateSlash(schedule.startAt) }}{{ schedule.endAt ? '〜' + toDateSlash(schedule.endAt) : ''
                            }} </label>
                        </template>
                      </div>
                    </div>
                  </template>
                </td>
                <td v-if="event" class="list-nowrap">{{ event.destination }}</td>
                <td v-if="category != '通年プログラム'" class="list-nowrap">
                  <span v-if="event?.priceDetail">
                    {{ event?.priceDetail.toLocaleString() }}{{ event?.priceDetail ? '円' : '' }}
                  </span>
                  <span v-if="!event?.priceDetail">
                    0円
                  </span>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      <div class="btn-area">
        <button class="btn btn-back" @click="$router.push({name: 'Top'})">TOPへ戻る</button>
      </div>
    </div>
    <div v-else-if="!useLoadingStore().isLoading">
      <p class="text-center">現在公開されているプログラムはありません</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { TEvent } from '@/types/event'
import { TCategoryType } from '@/types/search'
import { TTargetType } from '@/types/search'
import { toDateSlash} from '@/js/util'
import { handleErrors } from '@/config/handleErrors'
import { useLoadingStore } from '@/stores/loading'
import { API } from '../../api'

const events = ref<TEvent[]>([])
const category = ref<string | undefined>('')
const minYear: number = new Date().getFullYear()

const props = defineProps<{
  fiscal_year: string,
  category: string,
}>()
useLoadingStore().start()
const router = useRouter()

onMounted(async () => {
  try {
    const minFiscalYear: number = minYear % 100
    const fiscalYear2dig: number = Number(props.fiscal_year) % 100
    if (!fiscalYear2dig || fiscalYear2dig > minFiscalYear) router.push({ name: 'Top'})
    const { categoryTypes: categoryTypes } = await TEvent.fetchCategoryTypes()
    let categoryId: number | undefined | null = null
    if (props.category !== '') categoryId = categoryTypes.find(s => s.id === Number(props.category))?.id
    if (categoryId === undefined) router.push({ name: 'Top'})
    if (categoryId !== null) category.value = categoryTypes.find(s => s.id === categoryId)?.name
    const { events: fetchEvent }
      = await TEvent.fetchFiscalYearPrograms({ fiscalYear: fiscalYear2dig, category: categoryId })
    events.value = fetchEvent
    useLoadingStore().stop()
  } catch (e: any) {
    console.error(e)
    handleErrors(e)
  }
})

const minTarget = (event: TEvent) => {
  if (event && event.targetType.length > 0) {
    return event.targetType.reduce((min: TTargetType, current: TTargetType) => {
      return min.id < current.id ? min : current;
    })
  }
}

const maxTarget = (event: TEvent) => {
  if (event && event.targetType.length > 0) {
    return event.targetType.reduce((max: TTargetType, current: TTargetType) => {
      return max.id > current.id ? max : current;
    })
  }
}

const lineBreak = (name: string) => {
  name = name.replace(
    /([（(])(?!(日|月|火|水|木|金|土)([A-C])?[）)])/g, 
    '<br>$1'
  )
  name = name.replace(
    /([（(](日|月|火|水|木|金|土)([A-C])?[）)])/g, 
    '$1'
  )
  name = name.replace(/(3days|３ｄａｙｓ)/g, '$1<br>')
  name = name.replace(/(1期|１期)/g, '$1<br>')
  name = name.replace(/(2期|２期)/g, '$1<br>')
  name = name.replace(/(3期|３期)/g, '$1<br>')
  name = name.replace(/(「[A-Za-z0-9\s~\-]+」)/u, '<br>$1')
  return name
}

</script>
